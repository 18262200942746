.project-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.project-container:hover .project-image {
  transform: scale(1.1);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.project-container:hover .project-name {
  transform: scale(1.1);
  margin-top: 10px;
}
.project-image {
  width: 350px;
  height: 190px;
  object-fit: cover;
  transition: 0.5s;
}

.project-name {
  font-weight: 400;
  font-size: 20px;
  color: #272729;
  transition: 0.5s;
}

.about-image {
  width: 450px;
  margin-left: 50px;
}
.about-line {
  display: block;
}
.skill-div {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  margin-top: 25px;
}
.project-div {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  margin-top: 25px;
}
@media only screen and (max-width: 1200px) {
  .about-image {
    margin-left: 0px;
  }
  .project-div {
    grid-template-columns: auto auto;
  }
  .project-image {
    width: 300px;
    height: 162px;
  }
}
@media only screen and (max-width: 900px) {
  .about-image {
    width: 400px;
  }


  .skill-div {
    grid-template-columns: auto;
  }
}
@media only screen and (min-width: 1800px) {
  .about-line {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .skill-div {
    grid-column-gap: 10px;
  }

  .project-div {
    grid-template-columns: auto;
  }
  .about-image{
    margin-left: 0px;
    width: 340px;
  }
  
}
